<template>
  <div>
    <div class="head">
      <Head></Head>
    </div>

    <div class="section-bg">
      <!--
      浏览器限制有声音的视频即使添加自动播放属性autoplay也不能直接播放，添加muted意为静音播放即可实现自动播放。
      -->
      <video class="video-bg"
          autoplay loop playsinline muted
          poster="">
        <source
            src="@/assets/media/main-video.webm"
            type="video/mp4"
        />
      </video>
      <div class="section-tb color-1 z-index-10 frame-top">
        <h1>推动企业创造力向前发展</h1>
        <h3 class="margin-b-4">我们正在构建人工智能企业级应用，以开创创造力的新时代。</h3>
        <a class="btn-lg">免费注册试用</a>
      </div>
    </div>
    <div class="frame section-tb width-100pct">
      <h2>研究方向</h2>
      <p>解决传统作业方式，构建多模式人工智能系统以实现新型创意工具。</p>
      <video class="max-height-100vh"
          autoplay loop playsinline muted
          poster="">
        <source
            src="https://d3phaj0sisr2ct.cloudfront.net/site/videos/homepage/gen-2-middle-header-sequence.webm"
            type="video/mp4"
        />
      </video>
    </div>
    <div class="frame section-lr gap-flex-1r">
      <div class="img-container">
        <img src="@/assets/images/direction/zhsj-1.jpg"/>
      </div>
      <div class="text-img-container">
        <h2>智慧视觉方向</h2>
        <p>解决传统图像生产模式的效率</p>
        <img src="@/assets/images/direction/zhsj-2.jpg">
      </div>
    </div>
    <div class="frame section-lr gap-flex-1r">
      <div class="text-img-container">
        <h2>智慧中医</h2>
        <p>软硬件结合中医诊疗，为传统中医插上AI的翅膀 </p>
        <img src="@/assets/images/direction/zhzy-1.jpg">
      </div>
      <div class="img-container">
        <img src="@/assets/images/direction/zhzy-2.jpg"/>
      </div>

    </div>

    <div class="frame section-tb width-100pct">
      <h2>AI鉴定方向</h2>
      <p>视觉、声纹等AI造假调整行为鉴定，提升日常生活安全</p>
      <div class="img-container-lr">
        <img class="height-50vh" src="@/assets/images/direction/aijd-1.jpg">
        <img class="height-50vh" src="@/assets/images/direction/aijd-2.jpg">
      </div>
    </div>
    <div class="frame section-tb width-100pct">
      <h2>落地能力</h2>
      <p>探索我们的AI魔法工具</p>
      <div class="section-lr gap-flex-1r">
        <div style="min-width: calc(25% - 1rem);" class="flex-auto margin-b-4" v-for="(item,index) in aiToolList" :key="index">
          <img :src="item.imgPath">
          <p>{{item.title}}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>

  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Direction',
  components: {
    Head,
    Footer
  },
  data(){
    return {
      aiToolList:[
        {
          //使用require定义之后，可以动态使用，不用require，就只能写死路径（绝对路径）。不用require， :src="’…/img/image.jpg’" 会被解析为字符串。用了require，就是将图片当成模块先引进来，再绑定。
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"提高工作效率"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"商品评价分析"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"家庭伴读机器人"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"智能派工"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"智能创作平台"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"商品评论分析"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"家庭伴读机器人"
        },
        {
          imgPath:require("@/assets/images/direction/aijd-1.jpg"),
          title:"智能派工"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/index.scss";
</style>