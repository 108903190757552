var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"head"},[_c('Head',{ref:"head"})],1),_c('ScreenVideo',{attrs:{"video":"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/product-video.mp4","img":"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/product-video.jpg","title":"AI产品平台+传统行业","subtitle":"自主研发，专业领域安全可控，为细分行业提供智能化转型工具"}}),_c('div',{staticClass:"section-tb frame",attrs:{"id":"aiznpg"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('a',{staticClass:"btn-lg",on:{"click":_vm.showContactUs}},[_vm._v("产品咨询")])]),_c('div',{staticClass:"section-tb frame",attrs:{"id":"znbjzs"}},[_vm._m(3),_vm._m(4),_vm._m(5),_c('a',{staticClass:"btn-lg",on:{"click":_vm.showContactUs}},[_vm._v("产品咨询")])]),_c('div',{staticClass:"frame section-tb margin-b-4",attrs:{"id":"cpyj"}},[_vm._m(6),_vm._m(7),_c('a',{staticClass:"btn-lg",on:{"click":_vm.showContactUs}},[_vm._v("产品咨询")])]),_c('div',{staticClass:"footer"},[_c('Footer')],1),_c('ContactUs',{ref:"contactUs"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-container-lr"},[_c('h1',[_vm._v("AI智能派工")]),_c('p',[_vm._v("AI智能派工使用人工智能技术来"),_c('b',[_vm._v("自动化地分配和调度")]),_vm._v("工作任务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-lr margin-b-1",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"width-60pct margin-r-1 height-100pct"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/aiznpg-1-sm.jpg")}})]),_c('div',{staticClass:"flex-auto"},[_c('p',{staticClass:"para-md"},[_vm._v("根据任务的紧急程度、员工的技能和可用性、资源的分布、以及完成任务的最佳途径等，从而优化任务的分配，提高工作效率和效果。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-lr gap-flex-1r"},[_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("效率提升")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("通过自动化分配任务，减少人工干预，加快决策过程。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("优化资源分配")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("确保合适的员工根据其技能和任务需求被分配到合适的任务上，最大化资源利用率。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("改善服务质量")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("通过快速响应和准确派工，提升客户满意度和服务质量。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("数据驱动决策")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("AI系统可以学习和分析历史数据，以预测未来需求和优化派工计划。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("灵活性和可扩展")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("AI智能派工系统可以根据业务需求的变化灵活调整，支持业务的成长和扩展。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-container-lr"},[_c('h1',[_vm._v("智能编辑助手")]),_c('p',[_vm._v("AI传媒帮助传统媒体平台"),_c('b',[_vm._v("提升运作能力")]),_vm._v("。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-lr margin-b-1",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"flex-auto"},[_c('p',{staticClass:"para-md"},[_vm._v("为企业训练专属大模型，大幅提升内容生产效率，增强用户体验。")])]),_c('div',{staticClass:"width-60pct margin-l-1 height-100pct"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/znbjzs-1-sm.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-lr gap-flex-1r"},[_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("内容创作与编辑")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("自动撰写各类资讯文章、图片、视频，精准生成专业内容。自动修复老旧像、提高分辨率。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("个性化推荐")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("分析用户数据，为用户推荐个性化的新闻、文章、视频等内容，提升用户体验。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("数据分析与洞察")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("理解观众的兴趣和需求，指导内容创作和产品改进。协助分析社会热点、用户讨论和搜索趋势。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("自动化运营")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("辅助运营人员自动发布内容、管理多社交媒体账户、监测用户反馈，提高社交媒体运营的效率。")])]),_c('div',{staticClass:"flex-auto"},[_c('h4',[_vm._v("版权检测与内容")]),_c('p',{staticClass:"font-size-sm color-2"},[_vm._v("AI帮助识别和追踪未授权的内容分发，保护原创内容的版权。自动检测和过滤不适宜的内容。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-container-lr"},[_c('h1',[_vm._v("产品研究")]),_c('p',[_vm._v("传统"),_c('b',[_vm._v("纹样")]),_vm._v("数字作品库。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-lr width-100pct margin-b-2 gap-flex-1r"},[_c('div',{staticClass:"flex-auto"},[_c('div',{staticClass:"split-image-container height-66vh grid-2r-2c",staticStyle:{"width":"100%","gap":"5px","position":"relative"}},[_c('div',{staticClass:"split-part"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/ctwy-1.jpg")}})]),_c('div',{staticClass:"split-part"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/ctwy-2.jpg")}})]),_c('div',{staticClass:"split-part"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/ctwy-3.jpg")}})]),_c('div',{staticClass:"split-part"},[_c('img',{staticClass:"img-with-shadow",attrs:{"src":require("@/assets/images/product/ctwy-4.jpg")}})])]),_c('h2',{staticClass:"margin-t-1"},[_vm._v("传统纹样数字作品库")]),_c('p',{staticClass:"color-2"},[_vm._v("用于生成新的纹样设计、识别和分类现有纹样，增强和修复古老的纹样图案。应用于服装服饰、陶瓷制品、家居用品等领域。")])]),_c('div',{staticClass:"flex-auto"},[_c('img',{staticClass:"height-66vh img-with-shadow",attrs:{"src":require("@/assets/images/product/cpyj-2-sm.jpg")}}),_c('h2',{staticClass:"margin-t-1"},[_vm._v("古董和艺术品鉴定")]),_c('p',{staticClass:"color-2"},[_vm._v("通过AI大模型学习各门类古董的历史文化背景、材料工艺、款式铭文、风格及艺术特征、磨损和老化特征、修复和伪造迹象。机器识别细微的差异，这些差异可能对肉眼来说难以察觉。分析古董的历史记录、出处、以往交易记录等数据，通过大数据分析揭示古董之间的联系。同时，利用AI工具结合光谱分析技术，辅助进行古董断代。")])])])
}]

export { render, staticRenderFns }