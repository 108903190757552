<template>
  <div>
    <div class="head">
      <Head ref="head"></Head>
    </div>
    <ScreenVideo video=""
                 :img="mianImg"
                 title="行业智能化创新，帮助企业实现最大潜力。推动企业加速进入更智能、可持续的未来"
                 subtitle="玄合科技是一家提供垂直行业人工智能技术解决方案的公司。我们为企业构建可靠、专业、与业务高度契合的人工智能系统"></ScreenVideo>
<!--    <div class="section-bg">
      <img class="img-bg" src="@/assets/images/aboutus/main-2.jpg">
      <div class="section-tb color-1 z-index-10 frame-top text-align-center">
        <h1 class="">行业智能化创新，帮助企业实现最大潜力。推动企业加速进入更智能、可持续的未来</h1>
        <h3 class="margin-b-4 ">玄合科技是一家提供垂直行业人工智能技术解决方案的公司。我们为企业构建可靠、专业、与业务高度契合的人工智能系统</h3>
        <a class="btn-lg">免费注册试用</a>
        <div class="btn" style="width: 100px;">
          <img style="width:50px;height:50px" src="@/assets/images/icon/arrow-down.png">
        </div>
      </div>
    </div>-->

    <div id="sm" class="frame section-tb">
      <h1>我们的使命</h1>
      <div class="section-lr">
        <!--style="line-height:3rem"-->
        <p class="flex-auto margin-r-1 para-md" >用我们的人工智能技术赋能行业，解决专业领域面临的复杂挑战。<b>为企业创造实用、安全、并具有变革性的解决方案，用科技驱动企业发展</b>。携手建立智能、高效和可持续的商业世界。</p>
        <img class="width-50pct height-50vh img-with-shadow" src="@/assets/images/aboutus/women-1-sm.jpg">
      </div>
    </div>

    <div id="yj" class="frame section-tb">
      <h1>企业愿景</h1>
      <div class="section-lr">
        <img class="width-50pct height-50vh img-with-shadow" src="@/assets/images/aboutus/women-2-sm.jpg">
        <!--style="line-height:3rem"-->
        <p class="flex-auto margin-l-1 para-md">我们梦想构建一个在每个角落都能感受到AI正面影响的世界，无论是提高生产效率、改善健康医疗，还是保护环境资源。我们追求的不仅是技术上的突破，更是对社会进步的持续贡献。通过跨界合作和开放创新，<b>我们希望激发全球范围内对AI技术的探索与应用</b>，汇集行业专家、技术先驱和创新思想，共同开创一个更智能、更公平、更繁荣的未来。</p>
      </div>
    </div>

<!--    <div class="frame section-tb">
      <h2>我们的成长经历</h2>
      <h3>公司成长大事记。</h3>
      <div class="section-lr">
        <div class="section-tb flex-auto padding-t-2" style="background-color:#F0F0F0">
          <h2>2023-06</h2>
          <h3>成立</h3>
          <p>企业成立，首批合伙人入住。</p>
        </div>
        <div class="section-tb flex-auto padding-t-2" style="background-color:#E1E1E1">
          <h2>2023-08</h2>
          <h3>定位</h3>
          <p>公司服务和输出方向确定。</p>
        </div>
        <div class="section-tb flex-auto padding-t-2" style="background-color:#CACACA">
          <h2>2023-12</h2>
          <h3>搭建</h3>
          <p>底层能力搭建和网站上线。</p>
        </div>
      </div>
    </div>-->

    <div class="frame section-tb margin-b-4-75">
      <h1>加入团队</h1>
      <div class="width-100pct height-50vh" style="position:relative">
        <img class="height-50vh width-100pct img-with-shadow" src="@/assets/images/aboutus/women-3-sm.jpg">
        <a class="pstn-abslt-cntr color-1" style="text-decoration: underline;">
          <h2>查看更多职位</h2>
        </a>
      </div>

    </div>

    <div class="section-tb bg-color-3 frame-bottom">
      <h1>联系我们</h1>
      <h3>如何找到我们。</h3>
      <div class="grid-2c" style="position:relative;align-items: start">
        <div class="section-tb border-right-1 width-100pct"
             style="align-items: flex-start;align-content:flex-end;flex-wrap: wrap;padding-right: 2.8rem;">
<!--          <img src="@/assets/images/icon/i-text-telephone.png" class="icon-md">
          <h3 class="margin-l-0">400-8888888 转 2</h3>-->
          <div style="display:flex;align-items: center" class="ptnl-item">
            <img src="@/assets/images/icon/zixun.png" class="">
            <div class="margin-l-0 section-tb" style="align-items: flex-start;">
              <p class="" style="margin-bottom:0;line-height: 40px">项目/方案咨询</p>
              <p style="margin-bottom:0;line-height: 40px;font-weight: bold">pm@xuanhe.cc</p>
            </div>
          </div>
<!--          <p class="margin-l-0">pm@xuanhe.cc</p>-->


          <div style="display:flex;align-items: center;margin-top: 2rem" class="ptnl-item">
            <img src="@/assets/images/icon/address.png" class="">
            <div class="margin-l-0 section-tb" style="align-items: flex-start;">
              <p class="" style="margin-bottom:0;line-height: 40px">公司地址</p>
              <p style="margin-bottom:0;line-height: 40px;font-weight: bold">北京市海淀区中关村大街18号6层6189</p>
            </div>
          </div>
<!--          <p class="margin-l-0">北京市海淀区中关村大街18号6层6189</p>-->
        </div>
        <div class="section-tb height-100pct" style="padding-left: 6rem;">
<!--          <div class="section-lr margin-b-2">
            <input type="text" placeholder="称呼" style="width:20%" class="margin-r-1">
            <input placeholder="联系方式" class="flex-auto">
          </div>
          <textarea placeholder="描述您的问题" rows="3" class="margin-b-2"></textarea>
          <a class="btn-lg" @click="showContactUs">提交需求</a>-->
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="" prop="contacts" style="width:20%;display: inline-block">
              <el-input  v-model="form.contacts" autocomplete="off" placeholder="称呼"></el-input>

            </el-form-item>
            <el-form-item label="" prop="contactInfor" style="width: calc( 80% - 10px);float: right;display: inline-block">
              <el-input  v-model="form.contactInfor" autocomplete="off" placeholder="联系方式"></el-input>
            </el-form-item>
            <el-form-item label="" prop="description">
              <el-input v-model="form.description" type="textarea" :rows="3" resize="none"
                        placeholder="描述您的问题">
              </el-input>
            </el-form-item>
            <el-form-item style="text-align:center;margin-bottom: 0px">
              <el-button class="btn-lg" @click.stop="onSubmit()">提交需求</el-button>
            </el-form-item>
          </el-form>

        </div>
        <div class="pstn-abslt-cntr bg-color-3 padding-t-2">
          <h3>或</h3>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
    <ContactUs ref="contactUs"></ContactUs>
  </div>

</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import ContactUs from "@/components/ContactUs.vue"
import {Message} from "element-ui";
import {clueInput} from '@/api/contactus/contactus'
import ScreenVideo from '@/components/ScreenVideo.vue'

export default {
  name: 'Aboutus',
  components: {
    Head,
    Footer,
    ContactUs,
    ScreenVideo
  },
  data(){
    return{
      mianImg:require("@/assets/images/aboutus/main-2.jpg"),
      form: {
        contacts: '',
        contactInfor: '',
        description: ''
      },
      rules: {
        contacts:[
          { required: true, message: '请输入称呼', trigger: 'blur' }
        ],
        contactInfor:[
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],
        description:[
          { required: true, message: '请描述您的问题', trigger: 'blur' }
        ],

      }
    }
  },
  methods: {
    showContactUs(){
      this.$refs.contactUs.show();
    },
    onSubmit(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          clueInput(this.form).then(response => {
            this.resetForm("form")
            console.log("接口调用成功后的处理逻辑");
            Message.success("提交成功");

          });
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/index.scss";
</style>
