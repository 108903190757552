<template>
  <div class="login">
    <div class="login-form">
      <h3 class="title text-center">玄合科技 PRO</h3>
      <p class="subTitle text-center">利用人工智能提升创作效率</p>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账号密码登录" name="first">
          <el-form>
            <el-form-item label="" prop="username">
              <el-input prefix-icon="el-icon-user" placeholder="账号"></el-input>
            </el-form-item >
            <el-form-item>
              <el-input prefix-icon="el-icon-lock" placeholder="密码"></el-input>
            </el-form-item>
            <el-checkbox style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
            <el-link type="primary" style="float:right">忘记密码</el-link>
            <el-form-item style="width:100%;">
              <el-button round
                  :loading="loading"
                  size="medium"
                  type="primary"
                  style="width:100%;"
                  @click.native.prevent="handleLogin"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
              <div style="float: right;" >
                <el-link type="primary" style="line-height:1" v-if="register">注册账号</el-link>
              </div>
            </el-form-item>
          </el-form>

        </el-tab-pane>
        <el-tab-pane label="手机号登录" name="second">
          <el-form>
            <el-form-item label="" prop="username">
              <el-input prefix-icon="el-icon-user" placeholder="手机号"></el-input>
            </el-form-item >
            <el-form-item style="position: relative">
              <el-input prefix-icon="el-icon-lock" placeholder="验证码"></el-input>
              <button @click.prevent="getCode()"  class="code-btn" :disabled="!showCode">
                <span v-show="showCode">获取验证码</span>
                <span v-show="!showCode" class="count">{{count}} s</span>
              </button>
            </el-form-item>
            <el-checkbox style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
            <el-link type="primary" style="float:right">忘记密码</el-link>
            <el-form-item style="width:100%;">
              <el-button round
                         :loading="loading"
                         size="medium"
                         type="primary"
                         style="width:100%;"
                         @click.native.prevent="handleLogin"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
              <div style="float: right;" >
                <el-link type="primary" style="line-height:1" v-if="register">注册账号</el-link>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="el-login-footer">
      <span>Copyright © 2023 玄合科技有限公司出品</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'Login',
  data() {
    return {
      activeName: 'first',
      register: true,
      showCode: true,
      count:0,
      timer:null
    };
  },
  methods:{
    // 获取短信验证码
    getCode() {
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
.text-center {
  text-align: center;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background:$background-color-3;
  background-size: cover;

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    padding: 25px 25px 5px 25px;

    .title {
      margin: 0px auto 10px auto;
    }
    .subTitle{
      color:$font-color-2;
      font-size: 12px;
      margin: 0px auto 10px auto;
    }

    .el-input {
      height: 38px;
      input {
        height: 38px;
      }
    }
    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    /*color: #fff;*/
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: $font-color-2;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
</style>