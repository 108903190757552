<template>
  <div>
    <div class="head">
      <Head ref="head"></Head>
    </div>
    <ScreenVideo :video="vidos[vidoRandomNo].video"
                 :img="vidos[vidoRandomNo].img"
                 title="提供行业大模型训练，企业个性需求诊断，AI产品私有化部署"
                 subtitle="在农业、医疗、文化、消费领域专注AI一站式解决方案的研发及应用"></ScreenVideo>

    <div id="ny" class="frame section-tb">
      <div class="text-container-lr">
        <h1>农业</h1>
        <p>AI应用极大提高了农作物的<b>产量、效率</b>和可持续性。</p>
      </div>
      <div class="width-100pct margin-b-4">
        <el-tabs tab-position="top"  class=""><!--height-100vh-head-->
          <el-tab-pane label="种植">
            <span slot="label"><h2>种植</h2></span>
            <div class="section-lr" >
              <div class="flex-auto margin-r-1">
                <h2>智能监测与数据分析</h2>
                <p class="color-2">通过植入传感器，监测植物个体全生命周期状态。AI系统实时收集和分析农田的多维度数据（如土壤湿度、温度、作物生长状况等），我们帮助管理者做出更精准的决策，如灌溉时机和肥料施用量的确定。</p>
                <h2>病虫害识别与管理</h2>
                <p class="color-2">用AI图像识别技术可以准确识别作物病害和害虫，及时提醒农民采取措施，减少作物损失。这种技术通过分析作物叶片的图像来检测病害，从而实现早期诊断和治疗。</p>
                <h2>作物产量预测</h2>
                <p class="color-2">利用机器学习模型，预测作物产量，帮助农民和农业企业做出更好的计划和市场决策。</p>
              </div>
              <div class="width-50pct">
                <img class="img-with-shadow" src="@/assets/images/solution/nyzh-1-sm.jpg">
<!--                <video class=""
                       autoplay loop playsinline muted
                       poster="@/assets/media/solution-ny-zz.jpg">
                  <source
                      src="@/assets/media/solution-ny-zz.mp4"
                      type="video/mp4"
                  />
                </video>-->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="养殖">
            <span slot="label"><h2>养殖</h2></span>
            <div class="section-lr" >
              <div class="flex-auto margin-r-1">
                <h2>健康管理与监测</h2>
                <p class="color-2">我们通过AI系统自动监测动物的健康状况，通过分析粪便来预防疫情，监测热应激和评估动物福利，可以通过声音识别动物的异常行为。</p>
                <h2>喂养管理</h2>
                <p class="color-2">AI模型能够跟踪动物的喂养率，通过识别动物低头进食的行为，帮助管理者密切监测每个动物的健康状况。我们根据喂养率的减少获取健康状况不佳的信号，进行早期干预。</p>
              </div>
              <div class="width-50pct">
                <img class="img-with-shadow" src="@/assets/images/solution/nyyz-1-sm.jpg">
<!--                <video class=""
                       autoplay loop playsinline muted
                       poster="@/assets/media/solution-ny-yz.jpg">
                  <source
                      src="@/assets/media/solution-ny-yz.mp4"
                      type="video/mp4"
                  />
                </video>-->
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <a class="btn-lg" @click="showContactUs">解决方案咨询</a>
    </div>

    <div id="yljk" class="frame section-tb">
      <div class="text-container-lr">
        <h1>医疗健康</h1>
        <p>通过<b>数据分析</b>和<b>模式识别</b>优化健康管理及预防措施。</p>
      </div>
      <div class="section-lr margin-b-4">
        <div class="width-55pct">
          <img class="img-with-shadow" src="@/assets/images/solution/yljk-1-sm.jpg">
        </div>
        <div class="flex-auto margin-l-1">
          <h2>预测模型和流行病学研究</h2>
          <p class="color-2 para-md">构建疾病传播的预测模型，帮助管理部门和专业机构制定应对策略。通过分析大量的健康数据，AI模型可以预测疾病爆发的趋势和规模。</p>
          <h2>医学教育和培训</h2>
          <p class="color-2 para-md">生成逼真的医学案例和模拟患者互动，用AIGC技术生成实用的教学资源（如模拟真实口腔和牙齿状况）。医学模拟案例可以帮助医学生和医生练习临床技能，提高诊断和治疗的能力。</p>
        </div>
      </div>
      <a class="btn-lg" @click="showContactUs">解决方案咨询</a>
    </div>

    <div id="wh" class="frame section-tb">
      <div class="text-container-lr">
        <h1>文化</h1>
        <p>利用AI辅助<b>保护和复原珍贵的文化遗产</b>，推动艺术创作的多样化。</p>
      </div>
      <div class="section-lr margin-b-4">
        <div class="flex-auto margin-r-1">
          <h2>壁画数字化</h2>
          <p class="color-2 para-md">通过高精度扫描和AI图像处理技术，帮助科研人员复原受损的壁画，使这些珍贵的文化遗产得到更好的保存和传承。</p>
          <h2>古迹3D重建</h2>
          <p class="color-2 para-md">利用AI算法重建已经损坏或消失的历史建筑，进行精准场景还原，让人们能够以虚拟的方式体验这些建筑的原貌。</p>
        </div>
        <div class="width-55pct">
          <img class="img-with-shadow" src="@/assets/images/solution/wh-1-sm.jpg">
<!--          <video class=""
                 autoplay loop playsinline muted
                 poster="@/assets/media/solution-gjz.jpg">
            <source
                src="@/assets/media/solution-gjz.mp4"
                type="video/mp4"
            />
          </video>-->
        </div>
      </div>
      <a class="btn-lg" @click="showContactUs">解决方案咨询</a>
    </div>

    <div id="xf" class="frame section-tb">
      <div class="text-container-lr">
        <h1>消费</h1>
        <p><b>技术驱动营销策略</b>创新，AI能力为用户提供接近定制化的消费体验。</p>
      </div>
      <div class="width-100pct margin-b-4">
        <el-tabs tab-position="top"  class=""><!--height-100vh-head-->
          <el-tab-pane label="预测">
            <span slot="label"><h2>预测</h2></span>
            <div class="section-lr" >
              <div class="flex-auto margin-r-1">
                <h2>市场趋势分析和消费者行为预测</h2>
                <p class="color-2 para-md">利用机器学习算法，帮助企业分析大量的市场数据和消费者行为，预测未来的市场趋势和消费需求。我们帮助客户制定更有效的营销策略和产品开发计划。</p>
              </div>
              <div class="width-55pct">
                <img class="img-with-shadow" src="@/assets/images/solution/xfyc-1-sm.jpg">
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="试衣">
            <span slot="label"><h2>试衣</h2></span>
            <div class="section-lr" >
              <div class="flex-auto margin-r-1">
                <h2>虚拟试衣</h2>
                <p class="color-2 para-md">AI结合增强现实技术提供虚拟试衣功能，让用户在不实际试穿的情况下预览衣物效果。我们为客户同步在线上和实体店部署解决方案，帮助消费者做出更满意的购买决定。</p>
              </div>
              <div class="width-55pct">
                <img class="img-with-shadow" src="@/assets/images/solution/xfsy-1-sm.jpg">
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <a class="btn-lg" @click="showContactUs">解决方案咨询</a>
    </div>

    <div class="frame section-tb margin-b-4">
      <div class="text-container-lr">
        <h1>联系我们</h1>
        <p>我们会在<b>48小时</b>内联系您</p>
      </div>
      <div class="section-lr height-50vh">
        <div class="section-tb height-100pct width-50pct"
             style="align-items: flex-start;justify-content: flex-start; padding-right: 5rem">
<!--          <div class="section-lr margin-b-2">
            <input type="text" placeholder="称呼" style="width:20%" class="margin-r-1">
            <input placeholder="联系方式" class="flex-auto">
          </div>

          <textarea placeholder="描述您的问题" rows="3" class="margin-b-2"></textarea>

          <a class="btn-lg" @click="showContactUs">方案专家咨询</a>-->
          <el-form :model="form" ref="form" :rules="rules" style="width:100%">
            <el-form-item label="" prop="contacts" style="width:20%;display: inline-block">
              <el-input  v-model="form.contacts" autocomplete="off" placeholder="称呼"></el-input>

            </el-form-item>
            <el-form-item label="" prop="contactInfor" style="width: calc( 80% - 10px);float: right;display: inline-block">
              <el-input  v-model="form.contactInfor" autocomplete="off" placeholder="联系方式"></el-input>
            </el-form-item>
            <el-form-item label="" prop="description">
              <el-input v-model="form.description" type="textarea" :rows="3" resize="none"
                        placeholder="描述您的问题">
              </el-input>
            </el-form-item>
            <el-form-item style="text-align:center;">
              <el-butto class="btn-lg" @click.stop="onSubmit()">解决方案咨询</el-butto>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex-auto height-100pct">
          <img class="img-with-shadow" src="@/assets/images/solution/lxwm-1-sm.jpg">
        </div>
      </div>
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>

    <ContactUs ref="contactUs"></ContactUs>
  </div>

</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import ContactUs from "@/components/ContactUs.vue"
import {Message} from "element-ui";
import {clueInput} from '@/api/contactus/contactus'
import ScreenVideo from '@/components/ScreenVideo.vue'

export default {
  name:'Solution',
  components:{
    Head,
    Footer,
    ContactUs,
    ScreenVideo
  },
  data() {
    return {
      vidoRandomNo: Math.floor(Math.random() * 3), //返回一个 0 到 3-1 之间的随机整数
      vidos: [
        {
          img:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-video.jpg",
          video:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-video.mp4"
        },
        {
          img:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-ny.jpg",
          video:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-ny.mp4"
        },
        {
          img:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-gjz.jpg",
          video:"https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/solution-gjz.mp4"
        }
      ],
      form: {
        contacts: '',
        contactInfor: '',
        description: ''
      },
      rules: {
        contacts:[
          { required: true, message: '请输入称呼', trigger: 'blur' }
        ],
        contactInfor:[
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],
        description:[
          { required: true, message: '请描述您的问题', trigger: 'blur' }
        ],

      }

    }
  },
  methods: {
    showContactUs(){
      this.$refs.contactUs.show();
    },
    onSubmit(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          clueInput(this.form).then(response => {
            this.resetForm("form")
            console.log("接口调用成功后的处理逻辑");
            Message.success("提交成功");

          });
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
@import "~@/assets/styles/index.scss";

::v-deep .el-tabs__nav{
  left: 50%;
  transform: translateX(-50%) !important;
}

::v-deep .el-tabs__content{
  overflow: visible;
}
</style>