<template>
  <div>
    <div class="head">
      <Head ref="head"></Head>
    </div>

    <ScreenVideo video="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/product-video.mp4"
                 img="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/product-video.jpg"
                 title="AI产品平台+传统行业"
                 subtitle="自主研发，专业领域安全可控，为细分行业提供智能化转型工具"></ScreenVideo>

    <div id="aiznpg" class="section-tb frame">
      <div class="text-container-lr">
        <h1>AI智能派工</h1>
        <p>AI智能派工使用人工智能技术来<b>自动化地分配和调度</b>工作任务。</p>
      </div>
      <div class="section-lr margin-b-1" style="height: 50vh">
        <div class="width-60pct margin-r-1 height-100pct">
          <img class="img-with-shadow" src="@/assets/images/product/aiznpg-1-sm.jpg">
        </div>
        <div class="flex-auto">
          <p class="para-md">根据任务的紧急程度、员工的技能和可用性、资源的分布、以及完成任务的最佳途径等，从而优化任务的分配，提高工作效率和效果。</p>
        </div>
      </div>
      <div class="section-lr gap-flex-1r">
        <div class="flex-auto ">
          <h4>效率提升</h4>
          <p class="font-size-sm color-2">通过自动化分配任务，减少人工干预，加快决策过程。</p>
        </div>
        <div class="flex-auto ">
          <h4>优化资源分配</h4>
          <p class="font-size-sm color-2">确保合适的员工根据其技能和任务需求被分配到合适的任务上，最大化资源利用率。</p>
        </div>
        <div class="flex-auto ">
          <h4>改善服务质量</h4>
          <p class="font-size-sm color-2">通过快速响应和准确派工，提升客户满意度和服务质量。</p>
        </div>
        <div class="flex-auto ">
          <h4>数据驱动决策</h4>
          <p class="font-size-sm color-2">AI系统可以学习和分析历史数据，以预测未来需求和优化派工计划。</p>
        </div>
        <div class="flex-auto ">
          <h4>灵活性和可扩展</h4>
          <p class="font-size-sm color-2">AI智能派工系统可以根据业务需求的变化灵活调整，支持业务的成长和扩展。</p>
        </div>
      </div>
      <a class="btn-lg" @click="showContactUs">产品咨询</a>
    </div>

    <div  id="znbjzs" class="section-tb frame">
      <div class="text-container-lr">
        <h1>智能编辑助手</h1>
        <p>AI传媒帮助传统媒体平台<b>提升运作能力</b>。</p>
      </div>
      <div class="section-lr margin-b-1" style="height: 50vh">
        <div class="flex-auto">
          <p class="para-md">为企业训练专属大模型，大幅提升内容生产效率，增强用户体验。</p>
        </div>
        <div class="width-60pct margin-l-1 height-100pct">
          <img class="img-with-shadow" src="@/assets/images/product/znbjzs-1-sm.jpg">
        </div>
      </div>
      <div class="section-lr gap-flex-1r">
        <div class="flex-auto ">
          <h4>内容创作与编辑</h4>
          <p class="font-size-sm color-2">自动撰写各类资讯文章、图片、视频，精准生成专业内容。自动修复老旧像、提高分辨率。</p>
        </div>
        <div class="flex-auto ">
          <h4>个性化推荐</h4>
          <p class="font-size-sm color-2">分析用户数据，为用户推荐个性化的新闻、文章、视频等内容，提升用户体验。</p>
        </div>
        <div class="flex-auto ">
          <h4>数据分析与洞察</h4>
          <p class="font-size-sm color-2">理解观众的兴趣和需求，指导内容创作和产品改进。协助分析社会热点、用户讨论和搜索趋势。</p>
        </div>
        <div class="flex-auto ">
          <h4>自动化运营</h4>
          <p class="font-size-sm color-2">辅助运营人员自动发布内容、管理多社交媒体账户、监测用户反馈，提高社交媒体运营的效率。</p>
        </div>
        <div class="flex-auto ">
          <h4>版权检测与内容</h4>
          <p class="font-size-sm color-2">AI帮助识别和追踪未授权的内容分发，保护原创内容的版权。自动检测和过滤不适宜的内容。</p>
        </div>
      </div>
      <a class="btn-lg" @click="showContactUs">产品咨询</a>
    </div>

    <div id="cpyj" class="frame section-tb margin-b-4">
      <div class="text-container-lr">
        <h1>产品研究</h1>
        <p>传统<b>纹样</b>数字作品库。</p>
      </div>
      <div class="section-lr width-100pct margin-b-2 gap-flex-1r">
        <div class="flex-auto">
          <div class="split-image-container height-66vh grid-2r-2c"
               style="width:100%;gap:5px;position: relative;">
<!--            <div class="split-image-part top-left"></div>
            <div class="split-image-part top-right"></div>
            <div class="split-image-part bottom-left"></div>
            <div class="split-image-part bottom-right"></div>-->
            <div class="split-part ">
              <img class="img-with-shadow" src="@/assets/images/product/ctwy-1.jpg">
            </div>
            <div class="split-part ">
              <img class="img-with-shadow" src="@/assets/images/product/ctwy-2.jpg">
            </div>
            <div class="split-part ">
              <img class="img-with-shadow" src="@/assets/images/product/ctwy-3.jpg">
            </div>
            <div class="split-part ">
              <img class="img-with-shadow" src="@/assets/images/product/ctwy-4.jpg">
            </div>
          </div>
<!--          <img class="height-66vh img-with-shadow" src="@/assets/images/product/cpyj-1-sm.jpg">-->
          <h2 class="margin-t-1">传统纹样数字作品库</h2>
          <p class=" color-2">用于生成新的纹样设计、识别和分类现有纹样，增强和修复古老的纹样图案。应用于服装服饰、陶瓷制品、家居用品等领域。</p>
        </div>
        <div class="flex-auto">
          <img class="height-66vh img-with-shadow" src="@/assets/images/product/cpyj-2-sm.jpg">
          <h2 class="margin-t-1">古董和艺术品鉴定</h2>
          <p class=" color-2">通过AI大模型学习各门类古董的历史文化背景、材料工艺、款式铭文、风格及艺术特征、磨损和老化特征、修复和伪造迹象。机器识别细微的差异，这些差异可能对肉眼来说难以察觉。分析古董的历史记录、出处、以往交易记录等数据，通过大数据分析揭示古董之间的联系。同时，利用AI工具结合光谱分析技术，辅助进行古董断代。</p>
        </div>
      </div>
      <a class="btn-lg" @click="showContactUs">产品咨询</a>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>

    <ContactUs ref="contactUs"></ContactUs>
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import ContactUs from "@/components/ContactUs.vue"
import ScreenVideo from '@/components/ScreenVideo.vue'


export default {
  name: 'Product',
  components: {
    Head,
    Footer,
    ContactUs,
    ScreenVideo
  },
  data(){
    return {

    }
  },
  methods: {
    showContactUs(){
      this.$refs.contactUs.show();
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/index.scss";

.split-image-container{
  .split-part{
        //position: absolute;
    width:100%;
    height:100%;
  }
  .split-image-part{
    overflow: hidden;
    //position: absolute;
    width:100%;
    height:100%;
    background-image: url('~@/assets/images/product/cpyj-1-sm.jpg'); /* 设置背景图片 */
    background-repeat: no-repeat; /* 设置背景不重复 */
    background-size: 200% 200%; /* 背景图片大小设置为容器大小的2倍，以便可以定位到不同的部分 */
  }
  .top-left {
    background-position: top left;
    //background-position: 0 0; /* 左上角部分 x y */
  }

  .top-right {
    background-position: top right; /* 右上角部分 */
    //background-position: 100% 0; /* 右上角部分 */
  }

  .bottom-left {
    background-position: bottom left; /* 左下角部分 */
    //background-position: 0 100%; /* 左下角部分 */
  }

  .bottom-right {
    background-position: bottom right;/* 右下角部分 */
    //background-position: 100% 100%;/* 右下角部分 */
  }
}


</style>