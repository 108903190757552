<template>
  <div>
    <div class="head">
      <Head ref="head"></Head>
    </div>
    <ScreenVideo video="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/aitool-video.mp4"
                 img="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/aitool-video.jpg"
                 title="激发企业创造力的AI魔法工具"
                 subtitle="创意模拟、产品快速打样、运营效率提升"></ScreenVideo>
    <div id="wmds" class="frame section-tb">
      <div class="text-container-lr">
        <h1>外贸电商</h1>
        <p>面向<b>出海电商</b>的图片创作平台。</p>
      </div>
      <div class="section-lr margin-b-4" style="align-items: center">
        <div class="section-tb margin-r-1 height-100vh-head" style="width:33.3%;">
          <img class="img-with-shadow" style="height:80%" src="@/assets/images/aitool/dswm-1-sm.jpg">
          <!--          <a class="btn-lg">开始更换</a>-->
        </div>
        <div class="section-lr height-100vh-head" style="flex: 1 1">
          <img class="margin-b-1 width-pct-1gap-2 height-50pct-halfgap img-with-shadow" src="@/assets/images/aitool/dswm-2-sm.jpg">
          <img class="margin-b-1 width-pct-1gap-2 height-50pct-halfgap img-with-shadow" src="@/assets/images/aitool/dswm-3-sm.jpg">
          <img class="margin-b-1 width-pct-1gap-2 height-50pct-halfgap img-with-shadow" src="@/assets/images/aitool/dswm-4-sm.jpg">
          <img class="margin-b-1 width-pct-1gap-2 height-50pct-halfgap img-with-shadow" src="@/assets/images/aitool/dswm-5-sm.jpg">
        </div>
      </div>
      <p class="para-md">为内容创作者提供灵感和高质量商业配图。用户能够轻松找到符合需求的图片素材，AI智能推荐功能根据用户需求和趋势为其推荐最合适的图片内容，帮助用户快速找到创作灵感并提升内容质量。</p>
<!--      <a class="btn-lg" @click="showContactUs">联系产品服务</a>-->
    </div>

    <div id="dsp" class="frame section-tb">
      <div class="text-container-lr">
        <h1>短视频</h1>
        <p>短视频行业<b>脚本助手</b>。</p>
      </div>
      <div class="section-lr height-66vh width-100pct margin-b-4">
        <p class="width-pct-2gap-3 para-md">短视频行业标题自动扩写脚本，利用自然语言处理技术，快速生成吸引人的播报内容。从而提高视频输出效率，为内容创作者节省时间与精力。</p>
        <img class="width-pct-2gap-3 img-with-shadow" src="@/assets/images/aitool/dsp-1-sm.jpg">
        <img class="width-pct-2gap-3 img-with-shadow" src="@/assets/images/aitool/dsp-2-sm.jpg">
      </div>
<!--      <a class="btn-lg" @click="showContactUs">联系产品服务</a>-->
    </div>
    <div id="znyy" class="frame section-tb">
      <div class="text-container-lr">
        <h1>智能语音</h1>
        <p><b>语音理解和生成</b>为特定用户提供便捷日常。</p>
      </div>
      <div class="section-lr margin-b-1 height-50vh gap-flex-1r">
        <div style="width:33%">
          <img  class="img-with-shadow" src="@/assets/images/aitool/znyy-3-sm.jpg">
        </div>
        <div class="flex-auto">
          <img class=" img-with-shadow" src="@/assets/images/aitool/znyy-2-sm.jpg">
        </div>
      </div>
      <p class="margin-b-4 para-md">应用于阅读听书语音合成、订单播报语音、智能硬件语音合成等场景。通过深度学习模型和自然语言处理算法，将文字转换成自然流畅的语音，为用户提供便捷的听觉信息交流体验，提高智能设备的人机交互性能。</p>
<!--      <a class="btn-lg" @click="showContactUs">联系产品服务</a>-->
    </div>

    <div id="znsj" class="frame section-tb margin-b-4">
      <div class="text-container-lr">
        <h1>智能视觉</h1>
        <p>计算机视觉技术在<b>红酒和医美</b>行业的检测。</p>
      </div>
      <div class="section-lr width-100pct margin-b-4 gap-flex-1r">
        <div class="flex-auto"><!--width-pct-1gap-2-->
          <img class="margin-b-1 height-66vh img-with-shadow" src="@/assets/images/aitool/znsj-1-sm.jpg">
          <h2>医美皮肤状态识别</h2>
          <p class="color-2 para-md">通过分析图像特征，识别皮肤问题，提供个性化的护肤建议和治疗方案。</p>
        </div>
        <div class="flex-auto"><!--width-pct-1gap-2-->
          <img class="margin-b-1 height-66vh img-with-shadow" src="@/assets/images/aitool/znsj-2-sm.jpg">
          <h2>进口红酒识别</h2>
          <p class="color-2 para-md">智能红酒识别技术可以识别红酒的品牌、年份、产地等信息，辅助消费者进行选购和品鉴，提升消费体验和产品信任度。</p>
        </div>
      </div>
<!--      <a class="btn-lg" @click="showContactUs">联系产品服务</a>-->
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>

    <ContactUs ref="contactUs"></ContactUs>
  </div>

</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import ContactUs from "@/components/ContactUs.vue"
import ScreenVideo from '@/components/ScreenVideo.vue'

export default {
  name: 'Aitool',
  components: {
    Head,
    Footer,
    ContactUs,
    ScreenVideo
  },
  methods: {
    showContactUs(){
      this.$refs.contactUs.show();
    }
  }
}
</script>
<style scoped lang="scss">
@import "~@/assets/styles/index.scss";
</style>